#particles-js {
  background: $main;
  display: flex;
  vertical-align: top;
  min-height: 100vh;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;

  .particles-js-canvas-el {
    height: 100vh !important;
  }
}

.header {
  font-family: 'Montserrat', sans-serif;
  z-index: 1;
  text-align: center;
  color: #FFF;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  margin: 0 auto;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);

  .site-title {
    font-size: 52px;
    display: inline-block;
    line-height: 1;
    color: #FFF;

    @media only screen and (min-width:$cut) {
      margin: 0;
      font-size: 75px;
    }
  }

  .site-description {
    font-size: 16px;
    display: block;
    line-height: 1;
    color: #a9a9b3;
    margin-top: 16px;
    margin-bottom: 16px;

    @media only screen and (min-width:$cut) {
      font-size: 20px;
    }
  }

  .number {
    color: #ffb300;
    font-size: 68px;

    @media only screen and (min-width:$cut) {
      font-size: 92px;
    }
  }
}

.header-icons {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;

  a {
    // no borders
    outline: 0 !important;
    border: none !important;
    outline-style: none !important;
    -moz-outline-style: none !important;

    padding: 0px;
    margin: 0;
  }

  .icon {
    display: flex;
    justify-content: center;
    align-items: center;

    color: #FFF;
    width: 20px;
    height: 20px;
    font-size: 20px;
    padding: 10px;
    margin: 10px;
    border-radius: 25%;
    border: none;
    transition: all .5s;

    &:hover,
    &:active {
      color: $main;
      background: #FFF;
    }

    @media only screen and (min-width:$cut) {
      width: 30px;
      height: 30px;
      font-size: 30px;
      line-height: 30px;
      text-align: center;
    }
  }
}

.down {
  color: #FFF;
  position: absolute;
  bottom: 25px;
  width: 100%;
  margin: 0 auto;
  display: block;
  text-align: center;
  font-size: 30px;
  line-height: 30px;
  cursor: pointer;
  // no borders
  outline: 0 !important;
  border: none !important;
  outline-style: none !important;
  -moz-outline-style: none !important;

  .icon {
    position: absolute;
    -webkit-transform-style: preserve-3d;
    transform-style: preserve-3d;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    width: 100px;
    height: 100px;
    fill: #fff;
    -webkit-animation: pulse 1.3s infinite;
    animation: pulse 1.3s infinite;

    &:hover {
      color: $sec;
    }

    &:active {
      color: $sec;
    }
  }
}