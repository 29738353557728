@use "sass:math";

@-webkit-keyframes pulse {
  from {
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=40)";
    filter: alpha(opacity=40);
    opacity: .4;
    top: 0;
  }

  50% {
    -ms-filter: none;
    -webkit-filter: none;
    filter: none;
    opacity: 1;
    top: -10px;
  }

  to {
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=40)";
    filter: alpha(opacity=40);
    opacity: .4;
    top: 0;
  }
}

@keyframes pulse {
  from {
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=40)";
    filter: alpha(opacity=40);
    opacity: .4;
    top: 0
  }

  50% {
    -ms-filter: none;
    -webkit-filter: none;
    filter: none;
    opacity: 1;
    top: -10px
  }

  to {
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=40)";
    filter: alpha(opacity=40);
    opacity: .4;
    top: 0
  }
}

// Here's the meat and potatoes.

@mixin glitchCopy {
  content: attr(data-text);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.glitch {
  position: relative;
  color: white;
  /* Animation provies a slight random skew. Check bottom of doc
	for more information on how to random skew. */
  animation: glitch-anim 1s infinite linear alternate-reverse;

  // Creates a copy before our text. 
  &::before {
    // Duplicates our text with the mixin.
    @include glitchCopy;
    // Scoots text slightly to the left for the color offset.
    left: 2px;
    // Creates the color 'shadow' that happens on the glitch.
    text-shadow: -2px 0 #828eff;
    /* Creates an initial clip for our glitch. This works in
		a typical top,right,bottom,left fashion and creates a mask
		to only show a certain part of the glitch at a time. */
    clip: rect(44px, 450px, 56px, 0);
    /* Runs our glitch-anim defined below to run in a 5s loop, infinitely,
		with an alternating animation to keep things fresh. */
    animation: glitch-anim 5s infinite linear alternate-reverse;
  }

  // Creates a copy after our text. Note comments from ::before.
  &::after {
    @include glitchCopy;
    left: -2px;
    text-shadow: -2px 0 #000, 2px 2px #828eff;
    animation: glitch-anim2 1s infinite linear alternate-reverse;
  }
}

.glitch-hover:hover {
  position: relative;
  color: white;
  /* Animation provies a slight random skew. Check bottom of doc
  for more information on how to random skew. */
  animation: glitch-skew 1s infinite linear alternate-reverse;

  // Creates a copy before our text. 
  &::before {
    // Duplicates our text with the mixin.
    @include glitchCopy;
    // Scoots text slightly to the left for the color offset.
    left: 2px;
    // Creates the color 'shadow' that happens on the glitch.
    text-shadow: -2px 0 #828eff;
    /* Creates an initial clip for our glitch. This works in
    a typical top,right,bottom,left fashion and creates a mask
    to only show a certain part of the glitch at a time. */
    clip: rect(44px, 450px, 56px, 0);
    /* Runs our glitch-anim defined below to run in a 5s loop, infinitely,
  with an alternating animation to keep things fresh. */
    animation: glitch-anim 5s infinite linear alternate-reverse;
  }

  // Creates a copy after our text. Note comments from ::before.
  &::after {
    @include glitchCopy;
    left: -2px;
    text-shadow: -2px 0 #000, 2px 2px #828eff;
    animation: glitch-anim2 1s infinite linear alternate-reverse;
  }
}

@keyframes glitch-anim {
  $steps: 20;

  @for $i from 0 through $steps {
    #{math.percentage($i*math.div(1, $steps))} {
      clip: rect(math.random(100)+px, 9999px, math.random(100)+px, 0);
      transform: skew((math.random(100) * 0.01) + deg);
    }
  }
}

@keyframes glitch-anim2 {
  $steps: 20;

  @for $i from 0 through $steps {
    #{math.percentage($i*math.div(1, $steps))} {
      clip: rect(math.random(100)+px, 9999px, math.random(100)+px, 0);
      transform: skew((math.random(100) * 0.01) + deg);
    }
  }
}

@keyframes glitch-skew {
  $steps: 10;

  @for $i from 0 through $steps {
    #{math.percentage($i*math.div(1, $steps))} {
      transform: skew((math.random(10) - 5) + deg);
    }
  }
}