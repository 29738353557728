@import url('https://fonts.googleapis.com/css?family=Montserrat|Montserrat+Alternates|Raleway');
@import url('https://fonts.googleapis.com/css?family=Fredericka+the+Great|Josefin+Slab|Antic+Slab');

* {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

body {

    font-family: 'Inter UI', -apple-system, BlinkMacSystemFont, "Roboto", "Segoe UI", Helvetica, Arial, sans-serif;
    font-display: auto;

    margin: 0;
    padding: 0;
    font-size: 100%;
    line-height: 1.5;
    background-color: #292a2d;
    color: #a9a9b3;
}

article,
aside,
figcaption,
figure,
footer,
header,
nav,
section {
    display: block;
}

h1,
h2,
h3,
h4,
stong {
    color: #fff;
    margin-top: 1em;
    padding-top: 1em;
    line-height: 1.25;
}

.highlight {
    color: #ffb300;
}

h1 {
    font-size: 2.5rem;
}

h2 {
    font-size: 2rem;
}

h3 {
    font-size: 1.5rem;
}

h4 {
    font-size: 1rem;
}

ul,
ol {
    margin: 1em 0;
    padding-left: 40px;
}

p,
figure {
    margin: 1em 0;
}

a {
    text-decoration: none;
    color: #fff;
}

a img {
    border: none;
}

sup,
sub {
    line-height: 0;
}

b {
    color: #fff;
}

button {
    background-color: rgb(32, 33, 34);
    padding: 10px 15px;
    color: #ffb300;
}