.footer {
  background: $main;
  padding: 25px 0 15px;
  background-color: #202122;
  margin-top: 100px;
  text-align: center;
  color: #FFF;

  .love {
    color: red;
  }

  a {
    text-decoration: none;
    margin: 0;
    color: #FFF;

    &:hover {
      color: $sec;
    }

    &:active {
      color: $sec;
    }
  }
}