#about {
  font-size: 120%;

  width: 90%;
  margin: 0 auto;
  max-width: 960px;

  @media only screen and (min-width: $cut) {
    width: 80%;
  }

  svg {
    width: 60px !important;
    height: auto;
  }
}

.tech {
  margin: 10px;
  flex: 1 1 0;

  h2,
  h3 {
    font-weight: 400;
  }

  b {
    color: #fff;
  }
}

.sponsors {
  margin-top: 20px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 100px;

  @media screen and (max-width: $cut) {
    flex-direction: column-reverse;
  }
}

.sponsors div {
  vertical-align: middle;
}

.submit {
  background-color: #1B222C;
  border: none;
  color: white;
  padding: 16px 32px;
  text-align: center;
  font-size: 16px;
  margin: 4px 2px;
  opacity: 0.6;
  transition: 0.3s;
  display: inline-block;
  text-decoration: none;
  cursor: pointer;
}

.user {
  text-align: center;
  font-size: 60px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;

  p {
    font-size: 16px;
  }

  @media only screen and (min-width: $cut) {
    flex-wrap: nowrap;
    font-size: 75px;
  }
}

.user-details {
  text-align: justify;
  margin: 0 auto 5px;

  h1,
  h2 {
    font-weight: 400;
    margin-block-start: auto !important;
    margin-block-start: auto !important;
    padding-block-end: auto !important;
    padding-block-end: auto !important;
    text-align: center;
  }
}

.prizes {
  text-align: center;
  margin-top: -1em;
  font-size: 22px;
  color: white;

}

#first-place {
  color: #ffb300;
}

#second-place,
#third-place {
  color: #fff;
}

.next-year {
  padding: 10px;
  background: #ffb300;
  color: black;
}

.tickets {
  text-align: center;
  margin-top: 40px;
  padding: 10px;
  background: #ffb300;
  color: black;
  font-size: 1.5em;

  h1 {
    padding-top: 0px;
  }

  p {
    margin-bottom: 40px;
  }
}

.qualifiers {
  text-align: center;
  margin-top: 40px;
  padding: 10px;
  padding-top: 20px;
  background: #ffb300;
  color: black;
  font-size: 1.5em;

  h1 {
    padding-top: 0px;
  }

  p {
    margin-bottom: 40px;
  }
}

.call-for-papers {
  display: flex;
  gap: 5px;
  margin: 40px auto 0px;

  @media screen and (max-width: $cut) {
    flex-direction: column-reverse;
  }

}

.call-for-papers-content {
  padding: 15px;
  flex: 1;

  h1 {
    text-align: center;
    font-weight: 400;
    margin: 20px;
    padding: 0px;
  }

  p {
    text-align: justify;
    padding: 0px;
  }
}

.register-button {
  background-color: #363636;
  box-shadow: 0 5px 0 #000;
  color: white;
  padding: 0em 1.5vw;
  position: relative;
  text-decoration: none;
  text-transform: uppercase;
}

.register-button:hover {
  background-color: #000;
}

.sponsor_row {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 20px;
  margin-bottom: 20px;
}

.sponsor {
  width: 100%;
  zoom: 1.5;
  flex: 0 1 auto;
}

.sponsor_placeholder {
  background: #202122;
  border-style: solid;
  border-width: 3px;
  border-radius: 3px;
}

.sponsor_placeholder_platinum {
  border-color: lightblue;
  zoom: 1.50;
}

.sponsor_placeholder_gold {
  border-color: gold;
  zoom: 1.25;
}

.sponsor_placeholder_silver {
  border-color: silver;
  zoom: 1.00;
}

.sponsor_placeholder_bronze {
  border-color: sienna;
  zoom: 0.75;
}

.speaker-container {
  display: flex;
  align-items: center;
  text-align: left;
}

.placeholder-image {
  width: 40%;
  max-height: 150px;
  max-width: 150px;
  opacity: 0.25;
}

.speaker-image {
  width: 40%;
  max-width: 150px;
}

.talk-info {
  text-align: left;
  padding-left: 20px;
}

.talk-title {
  font-size: 22px;
  font-weight: bold;

  @media screen and (max-width: $cut) {
    font-size: 20px;
  }
}

.speaker-name {
  font-size: 20px;
  font-style: italic;
  color: #fff;

  &:hover {
    color: $sec;
  }

  &:active {
    color: $sec;
  }

  cursor: pointer;

  @media screen and (max-width: $cut) {
    font-size: 18px;
  }
}

.talk-description {
  padding-top: 10px;
  font-size: 18px;
  text-align: justify;

  @media screen and (max-width: $cut) {
    font-size: 16px;
  }
}

.speaker-name .speaker-bio {
  visibility: hidden;
  max-width: 780px;
  border-radius: 6px;
  padding: 6px;
  margin-top: 30px;
  margin-right: 10%;
  background-color: #fff;
  position: absolute;
  z-index: 1;
  font-size: 14px;
  color: #363636;

  @media screen and (max-width: $cut) {
    margin-right: 5%;
    font-size: 12px;
  }
}

#overflow {
  font-family: monospace;
  text-align: left;
}

#segfault {
  visibility: hidden;
  color: #a81313;
}

.speaker-name .show {
  visibility: visible;
  -webkit-animation: fadeIn 1s;
  animation: fadeIn 1s;
}

@-webkit-keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}